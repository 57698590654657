



/* Agenda main */
.Agenda {
    margin-bottom: 100px;
    width: 100%;
}



/* fim agenda main*/


.react-calendar__navigation button[disabled] {
    background-color: #FFFFFF;
    border: 1px solid #EEEEEF;
    box-sizing: border-box;
    border-radius: 3px;
    color: #E3E4E6 !important;
}

.react-calendar__navigation button svg {
    width: 16px;
    height: 14px;
}

.react-calendar__navigation button[disabled] svg path {
    fill: #E3E4E6 !important;
}

.react-calendar__navigation button:not(:disabled):first-child svg path,
.react-calendar__navigation button:not(:disabled):last-child svg path {
    fill: #0054A6 !important;
}

.react-calendar__navigation button:not(:disabled):last-child {
    background-color: #FFFFFF;
    border: 1px solid #0054A6;
    box-sizing: border-box;
    border-radius: 3px;
}

.react-calendar__navigation button:not(:disabled):first-child {
    background-color: #FFFFFF;
    border: 1px solid #0054A6;
    box-sizing: border-box;
    border-radius: 3px;
}

.react-calendar__navigation__arrow {
    background: #FFFFFF;
    border: 1px solid #0054A6;
    box-sizing: border-box;
    border-radius: 3px;
}

.divAgendaContinuar {
    text-align: center;
    margin-top: 35px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
}

.containBtn{
    display: flex;
    flex-direction: row;
    align-items: center;
   
}

/* horarios  */
.horarios {
    margin-top: 50px;
    width: 100%;
}


    .horarios span {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        color: #0054A6;
        margin-left: 5px;
        margin-bottom: 40px;
    }

.principalTXT {
    justify-content: left !important;
    align-items: left !important;
    text-align: left !important;
}

.gridBtns {
    justify-content: center;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, 150px);
    grid-gap: 22px 22px;
    padding: 10px;
    margin-top: 25px
}

.semHorarios {
    color: #666666;
    font-size: 14px;
    margin-top: 30px;
}
/* tabs */
.tab-wrapper {
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.nav-tabs {
    border-bottom: 1px solid #C4C4C4;
}

    .nav-tabs .nav-link {
        margin-bottom: -1px;
        border: 1px solid transparent;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        font-weight: normal;
        font-size: 18px;
        color: #757575;
        min-width: 50%;
        max-width: 394px;
        height: 56px;
        padding-top: 15px;
    }

        .nav-tabs .nav-link.active {
            font-weight: bold;
            font-size: 18px;
            color: #0054A6;
            background: #EEF7FF;
            border-radius: 3px 3px 0px 0px;
            border-bottom: 1px solid #C4C4C4;
            border-left: 1px #EEF7FF solid;
            border-top: 1px #EEF7FF solid;
            border-right: 1px #EEF7FF solid;
            padding-top: 15px;
        }
/* fim horarios*/

.gridBtns > button {
    background: #FFFFFF;
    border: 1px solid #72737D;
    box-sizing: border-box;
    border-radius: 3px;
    text-align: center;
    padding: 10px 5px;
    font-size: 18px;
    color: #515151;
    width: 150px;
    height: 56px;
}




.activeButton {
    background: #0054A6 !important;
    color: #FFFFFF !important;
    border: 1px solid #72737D !important;
}



/* fim horarios */
.calendar-cointainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.react-calendar {
    width: 100%;
    background-color: #ffffff;
    border: none;
    text-decoration: none;
}


.react-calendar__navigation button {
    border: #0054A6 2px;
    width: 64px;
    height: 40px;
    color: #E3E4E6;
}



abbr {
    text-decoration: none !important;
}

.react-calendar button {
    color: #222730;
    font-weight: bold;
}

.react-calendar__tile {
    position: relative;
    overflow: visible !important;
    min-height: 42px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

    .react-calendar__tile abbr {
        width: 42px;
    }

.react-calendar__month-view__weekdays {
    text-decoration: none !important;
    color: #515151;
    font-weight: bold;
    font-size: 14px;
}

.react-calendar__month-view__days__day--weekend {
    color: #222730;
}


.react-calendar__tile--now {
    color: #014CAB;
    background: #ffffff;
}

    .react-calendar__tile--now:enabled:hover {
        background: #e6e6e6;
    }

.react-calendar__tile:enabled:hover {
    background: none !important;
}

    .react-calendar__tile:enabled abbr:hover {
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 12px;
        padding-bottom: 12px;
        background: #014CAB !important;
        color: #ffffff !important;
        border-radius: 50%;
    }

.react-calendar__tile--active {
    background: none !important;
}

    .react-calendar__tile--active abbr {
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 12px;
        padding-bottom: 12px;
        background: #014CAB !important;
        color: #ffffff !important;
        border-radius: 50%;
    }


.react-calendar__tile:enabled:hover:focus:active::after::before {
    background: #014CAB;
    color: #ffffff;
}

.react-calendar__tile--active:hover:focus:active::after::before {
    background: #014CAB;
    color: #ffffff;
}


.react-calendar__tile--active:enabled:hover:focus:active:after::before {
    background: #014CAB;
    color: #ffffff;
}


.react-calendar__tile:disabled {
    background-color: #ffffff;
    color: #ADB9C4;
}




.react-calendar__navigation button:enabled:hover {
    background-color: #ffffff;
}

.react-calendar__navigation__label__labelText {
    color: #0054A6 !important;
    font-size: 18px;
    border: none !important;
}

.react-calendar__navigation button {
    border: #0054A6 2px;
    color: #0054A6 !important;
}



.continuarBtn {
    width: 218px;
    height: 43px;
    border: none;
    background: #09B2AB;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 16px;
    margin-top: 50px;
    align-items: center !important;
    justify-content: center !important;
}


.verHorariosBtn {
    height: 43px;
    width: 50%;
    border: 1px solid #0054A6;
    background: #ffffff;
    border-radius: 6px;
    color: #0054A6;
    font-size: 16px;
}

@media screen and (max-width: 400px) {
    .verHorariosBtn {
        width: 70%;
    }
}

/* DESKTOP */
@media screen and (min-width: 1200px) {
    .Agenda {
        background: #fff;
        width: 100%;
        max-width: 788px;
        min-height: 180px;
        border-radius: 8px;
        padding: 15px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
    }
}




.continuarLink {
    width: 220px;
    height: 43px;
    border: none;
    background: #09B2AB;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 16px;
    margin-top: 50px;
    align-items: center !important;
    justify-content: center !important;
    text-align: center;
    padding-top: 10px;
}

    .continuarLink:hover {
        text-decoration: none;
        background: #09B2AB;
        color: #FFFFFF;
    }
    
.lojaVoltarBtn {
    width: 100%;
    max-width: 240px;
    height: 43px;
    border: 1px solid #0054A6;
    box-sizing: border-box;
    border-radius: 6px;
    color: #0054A6;
    background: #FFFFFF;
}

@media(max-width: 1023px) {
    .containBtn {
        width: 100%;
        padding: 16px 11px;
        /*position: fixed;
        bottom: 0;*/
        position: absolute;
        bottom: -100px;
        justify-content: center;
        align-items: center;
        z-index: 999;
        background-color: white;
        border-top: 1px solid #d3d3d3;
    }
}

.agendaNaoEncontrada {
    text-align: center;
    width: 280px;
}

    .agendaNaoEncontrada h5 {
        padding-bottom: 0;
        color: #666666;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
    }

    .agendaNaoEncontrada p {
        padding-bottom: 0;
        color: #666666;
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
    }

.ReactModal__Content.ReactModal__Content--after-open {
    top: 250px;
}

.overlay-modal-loading { 
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: hsla(0,0%,100%,.6);
}

    .overlay-modal-loading span {
        font-size: 1.5em;
        margin-top: 1rem;
    }

@media screen and (max-width: 1024px) {
    .ReactModal__Content.ReactModal__Content--after-open {
        top: 190px;
    }
}


@media screen and (max-width: 768px) {

    .gridBtns {
        display: grid;
        grid-template-columns: repeat(auto-fit, 140px);
        grid-gap: 20px 25px;
        padding: 10px;
    }


    .Agenda {
        margin-top: 30px;
        margin-bottom: 100px;
        background-color: #fff;
    }

    .react-calendar__navigation button {
        width: 43px;
        height: 27px;
    }

        .react-calendar__navigation button[disabled] {
            width: 43px;
            height: 27px;
        }


    .react-calendar__month-view__weekdays {
        text-decoration: none !important;
        color: #515151;
        font-weight: bold;
        font-size: 12px;
    }



    .react-calendar__tile:enabled:hover abbr {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .react-calendar__tile--active abbr {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}



@media screen and (max-width: 320px) {
    .gridBtns {
        display: grid;
        grid-template-columns: repeat(auto-fit, 120px);
        grid-gap: 20px 25px;
        padding: 10px;
    }

        .gridBtns > button {
            width: 130px;
            height: 50px;
        }

    .react-calendar__tile--active abbr {
        padding: 8px;
        background: #014CAB !important;
        color: #ffffff !important;
        border-radius: 50%;
    }

    .react-calendar__tile:enabled:hover abbr {
        padding: 7px;
        background: #014CAB !important;
        color: #ffffff !important;
        border-radius: 50%;
    }


    .react-calendar__tile:enabled:hover abbr {
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .react-calendar__tile--active abbr {
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

.continuarLinkFloat, .continuarBtnFloat {
    width: 322px;
    height: 43px;
    border: none;
    border-radius: 6px;
    padding: 8px 0;
    display: flex;
    justify-content: center;
    color: #FFFFFF;
    transition: 0.1s ease;
    cursor: pointer;
    background-color: #09B2AB;
    margin-left: -20px;
}

.calendar {
    margin-bottom: 0 !important;
}

.calendar .calendarComponent {
    width: 372px;
}

.empty-data {
    display: flex;
    flex-direction: column;
    align-items: center;
}
