.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.div100 {
    width: 100%;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spiner {
  animation: App-logo-spin 2s linear infinite;
}

.item1 { grid-area: mn;}
.item2 { grid-area: bd; }

.grid-container {
  display: flex;
}

.grid-container #appTitle {
  margin-left: 31px;
}

@media (max-width: 1024px) {

  .grid-container #appTitle {
    margin-left: 11px;
    margin-top: 22px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .grid-container #clinic-farm {
    width: 334px;
    text-align: left;
  }

}

@media (max-width: 1024px) {
  .grid-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 3rem;
  }

  .grid-container .item1 {
    z-index: 2;
  }

  .grid-container .item1 > div {
    height: 100%;
  }

  .grid-container .item2 {
    position: absolute;
    top: 55px;
  }

  .item2 { 
    padding-left: 10px;
    padding-right: 10px;
  }
}

.d-none {
  display: none;
}

#outer {
    z-index: 99999;
    /*border: 1px solid red;*/
    width: 100%;
    /*height: 75px;*/
    display: flex;
    justify-content: center !important;
    bottom: 0px;
    position: fixed;
    background-color: #FFF;
    padding-top: 5px;
}

/* TODO: ANALISAR */
.modal-reagendamento .ReactModal__Content.ReactModal__Content--after-open {
    /*top: 50% !important;*/
    top: 250px !important;
}

.modal-cancelamento .ReactModal__Content.ReactModal__Content--after-open {
    /*top: 50% !important;*/
    top: 250px !important;
}

.modal-simple .ReactModal__Content.ReactModal__Content--after-open {
  top: 250px !important;
}

.modal-simple.custom-loading .ReactModal__Content.ReactModal__Content--after-open {
  display: flex;
  justify-content: center;
  max-height: 300px!important;
  min-height: 300px!important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open.overlay__modalEnderecoPagamento {
  z-index: 9908 !important;
  position: fixed;
  inset: 0px;
}

@media (max-width: 1024px) {
  .ReactModal__Overlay.ReactModal__Overlay--after-open:not(.overlay__modalEnderecoPagamento) {
    z-index: 999999 !important;
  }

  .ReactModal__Content.ReactModal__Content--after-open {
    margin-top: unset;
    transform: translate(-50%, -50%);
    margin-top: -50%;
  }
}

@media (max-width: 767px) {
  .ReactModal__Content.ReactModal__Content--after-open {
    position: unset !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    transform: translate(0%, 15%) !important;
    margin: 0 auto !important;
    max-width: 80% !important;
  }

  .grid-container .item2 {
    padding: 0 1rem;
  }

  .modal-reagendamento .ReactModal__Content.ReactModal__Content--after-open {
    /*top: 50% !important;*/
    top: 250px !important;
    width: 95% !important;
  }

  .modal-cancelamento .ReactModal__Content.ReactModal__Content--after-open {
     /*top: 50% !important;*/
    top: 250px !important;
    width: 95% !important;
  }

  .modal-simple .ReactModal__Content.ReactModal__Content--after-open {
    /*top: 50% !important;*/
    top: 250px !important;
    width: 95% !important;
  }
}

[class*="-singleValue"] {
  font-family: "Source Sans Pro", sans-serif;
}

.button-link {
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  text-decoration-line: underline;
  color: #0054A6;
  background: transparent;
  border: 0;
}

div.categories {
  margin: 0 31px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}

@media (max-width: 1024px) {
  
  div.categories {
    grid-template-columns: unset;
    margin: 0;
    grid-row-gap: 1rem;
    padding: 0 8px;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.modal-remover-paciente h2, 
.modal-paciente-removido h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500 !important;
  margin-top: 10px !important;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #383838;
}

.modal-remover-paciente p, 
.modal-paciente-removido p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 20px;
  text-align: center;
  color: #666666 !important;
}

.swal2-container {
  z-index: 9908 !important;
}