.firstLogin {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255);
  z-index: 999;

  &__banner{
    flex: 2;
    height: 100vh;

    img{
      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  &__modal {
    padding: 0 60px 0 60px;
    flex: 1;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &__window{
      max-width: 360px;
      flex: 1;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-flow: column;
      overflow: hidden;
      border-radius: 8px;
      border: 2px solid #0054a5;
      box-shadow: 4px 6px 6px 2px rgba(0, 0, 0, 0.6);
      overflow: hidden;

      &__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #0054a5;
        height: 65px;
        border-radius: 0;

        > h4 {
          padding: 0;
          margin: 0;
          color: #fff;
        }
      }

      &__content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: auto;
        flex-flow: column;
        padding: 0 20px 0 20px;
        flex: 1;
        background: white;

        > h5 {
          align-self: flex-start;
          font-weight: 600;
          font-size: 16px;
          margin: 15px 0;
        }

        &__field {
          width: 100%;
          display: flex;
          align-items: center;
          flex-flow: column;
          justify-content: center;
          margin: 5px 0;

          > label {
            align-self: flex-start;
            margin: 0 0 2px 10px;
            font-size: 12px;
          }

          > input {
            border: 1px solid #0054a5;
            color: #666360;
            width: 100%;
            height: 48px;
            outline: none;
            padding: 0 12px;
            font-size: 14px;
            box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.6);
          }
        }

        .forgotPass {
          cursor: pointer;
          text-decoration: underline;
          align-self: flex-end;
          color: #0054a5;
          margin: 8px 0 0 0;
          font-size: 14px;
        }
      }

      &__footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-flow: column;
        padding: 10px 20px 20px 20px;
        background: white;

        .signUp {
          cursor: pointer;
          text-decoration: underline;

          color: #0054a5;
          margin: 8px 0 0 0;
          font-size: 14px;
        }
      }
    }
  }
}

.secondaryBtn {
  width: 220px;
  height: 43px;
  border: none;
  box-sizing: border-box;
  border-radius: 4px;
  color: #444;
  background: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.primaryBtn {
  height: 43px;
  width: 220px;
  border: none;
  background: #0054a5;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  box-shadow: 1px 4px 4px 1px rgba(0, 0, 0, 0.5);
}

.backBtn {
  background: #fff;
  color: #0054a5;
  align-self: flex-start;
  border: none;
  font-size: 14px;
  font-weight: 500;
  margin: 10px 0;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
