.errorCPF div {
    border-color: #CC1117;
}
.errorCPF label {
    color: #CC1117;
}
.linkTermos {
    text-decoration: underline;
    font-weight: bold;
}

.agendamentoBtnFloat {
    width: 322px;
    height: 43px;
    border: none;
    border-radius: 6px;
    padding: 8px 0;
    display: flex;
    justify-content: center;
    color: #FFFFFF;
    transition: 0.1s ease;
    cursor: pointer;
    background-color: #09B2AB;
    margin-left: -60px;
}