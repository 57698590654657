.justifyCenter {
    justify-content: center !important;
}
/*
.slick-slide {
    width: 300px !important;
}

.slick-track {
    left: 15px;
}

@media (max-width: 1024px) {
    .slick-track {
        left: 0;
    }
}
*/
.divFloating {
    border: 1px solid black;
    width: 400px;
    margin: 0 auto;
    height: 120px;
    position: fixed;
    bottom: 120px;
    z-index: 9999;
}