.labelDropDown {
    font-size: 12px;
    line-height: 20px;
    color: #828282;
    margin-bottom: 0;
}

.DropDown {
    font-size: 14px;
    line-height: 20px;
    color: #A9A9A9;
}

.paddingBotton44 {
    padding-bottom: 44px;
}

.divCenterLojas {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 2.5rem 0rem;
}

.divCardLoja {
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 115px;
    margin: 0 1rem 2rem;
    border: 1px solid #0054A6;
    box-sizing: border-box;
    border-radius: 4px;
}

.divCardLojaBody {
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.divCenterLojas:after {
    content: "";
    display: table;
    clear: both;
}

@media (max-width: 1024px) {
    .DropDown {
        font-size: 16px !important;
    }

    .divCenterLojas {
        padding-bottom: 100px;
    }
    
    .divCenterLojas .divCardLojaBody .cardLojaTitle {
        font-size: 16px;
    }

    .divCenterLojas .divCardLojaBody p {
        font-size: 12px;
    }

    .LojaContinuarBtn {
        width: 218px;
    }

    .LojaVerMaisBtn {
        width: 308px !important;
        margin-bottom: 75px;
    }
}

@media (max-width: 767px) {
    .divCardLoja {
        max-width: 60%;
    }
}

@media (max-width: 575px) {
    .divCardLoja {
        max-width: 100%;
    }
}
/*

.cardLoja {
    margin-bottom: 36px;
    border: 1px solid #0054A6;
    box-sizing: border-box;
    border-radius: 4px;
}*/

.cardLojaBody {
    height: 113px;
    padding-top: 24px;
    padding-left: 31px;
}

.divCardLojaBody p {
    margin-bottom: 5px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
}

.divCardLojaBody p:last-child {
    margin-bottom: 0
}

.divCardLojaBody .cardLojaTitle {
    text-transform: capitalize;
    color: #0054A6;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
}

.cardLojaActived {
    background: #0054A6;
}

    .cardLojaActived p {
        color: #fff;
        margin-bottom: 5px;
    }

    .cardLojaActived .cardLojaTitle {
        color: #fff;
    }

.unavailable {
    cursor: not-allowed;
    background: #e9ecef;
    border-color: #a9a9a9;
}

    .unavailable p {
        color: #a9a9a9;
    }

    .unavailable .cardLojaTitle {
        color: #666;
    }

#tooltip-unavailable .tooltip-inner {
    background-color: #666 ;
    color: #fff;
  }
  
#tooltip-unavailable .bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before{
    border-top-color:#666 ;
}

.voltarBtn {
    height: 43px;
    width: 308px;
    border: none;
    background: #0054A6;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 16px;
    margin-top: 50px;
    align-items: center !important;
    justify-content: center !important;
}

.msgLojaNaoEncontrada {
    padding-left: 50px;
    padding-right: 50px;
}

.msgLojaNaoEncontrada p{
    margin-bottom: 0px;
}


@media (max-width: 1024px) {

    .msgLojaNaoEncontrada {
        padding-left: 20px;
        padding-right: 20px;
    }
}

    .lojaVoltarBtn {
        width: 240px;
        height: 43px;
        margin-right: 16px;
        border: 1px solid #0054A6;
        box-sizing: border-box;
        border-radius: 6px;
        color: #0054A6;
        background: #FFFFFF;
    }
 

    .LojaContinuarBtn {
        height: 43px;
        width: 240px;
        border: none;
        background: #09B2AB;
        border-radius: 6px;
        color: #FFFFFF;
        font-size: 16px;
        align-items: center !important;
        justify-content: center !important;
    }

    .LojaVerMaisBtn {
        height: 43px;
        width: 218px;
        border: none;
        background: #0054A6;
        border-radius: 6px;
        color: #FFFFFF;
        font-size: 16px;
        align-items: center !important;
        justify-content: center !important;
    }

    .lojaNaoEncontrada {
        text-align: center;
    }

        .lojaNaoEncontrada h5 {
            margin-bottom: 0;
            color: #666666;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
        }

        .lojaNaoEncontrada p {
            padding-bottom: 0;
            color: #666666;
            font-style: normal;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
        }

    .divCenterLoja {
        width: 70%;
        margin: 0 auto;
    }

    @media (max-width: 767px) {
        .divCenterLoja {
            width: 90%;
        }
    }

    @media (max-width: 575px) {
        .divCenterLoja {
            width: 100%;
        }
    }

.LojaContinuarBtnFloat {
    height: 43px;
    width: 322px;
    border: none;
    background: #09B2AB;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 16px;
    align-items: center !important;
    justify-content: center !important;
}
